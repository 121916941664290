import axios from "axios";
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import Spinner from "../../components/animations/spinner";
import Icon from "../../components/icons";
import { Typography } from "../../components/utils";
import Layout from "../../components/layout";
import QueryStringRetriever from "../../utils/queryStringRetriever";
import { setSession } from "../../utils/localStorage";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import * as styles from "./styles.module.scss";

const RetrieveItinerary = () => {
  const [status, setStatus] = useState("retrieving");

  useEffect(() => {
    let QS = QueryStringRetriever;
    if (QS().id && QS().uuid && QS().v) {
      retrieveItinerary();
    } else {
      setStatus("error");
    }
  }, []);

  const retrieveItinerary = () => {
    const { id, uuid, v } = QueryStringRetriever();
    axios
      .post(`${CONNECT}/checkout/session/validate`, {
        client: CONNECT_CLIENT,
        id,
        uuid,
        v,
      })
      .then((r) => {
        if (r.data && r.status === 200) {
          setSession(false);
          setSession(r.data);
          if (typeof window !== "undefined")
            navigate(`/my-account?orderId=${id}`);
        } else {
          setStatus("error");
        }
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  if (status === "retrieving") {
    return (
      <Layout
        metatags={{ title: "Retrieve Itinerary" }}
        hideMobileFooter={true}
        backgroundColor={"#f4f4f9"}
      >
        <div className={styles.retrieveItinerary}>
          <Spinner className={styles.loader} />
          <Typography variant="h400-medium" component="span">
            Retrieving your itinerary...
          </Typography>
        </div>
      </Layout>
    );
  }
  if (status === "error") {
    return (
      <Layout
        metatags={{ title: "Retrieve Itinerary" }}
        hideMobileFooter={true}
        backgroundColor={"#f4f4f9"}
      >
        <div className={styles.retrieveItinerary}>
          <Icon
            // className={styles.errorIcon}
            icon="AlertTriangle"
            size="Small"
          />
          <Typography variant="h400-medium" component="span">
            An error ocurred trying to retrieve your itinerary
          </Typography>
        </div>
      </Layout>
    );
  }
};

export default RetrieveItinerary;
