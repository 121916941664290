import React from "react";
import * as styles from "./styles.module.scss";

function Spinner(props) {
  return (
    <div className={`${props.className}`}>
      <svg className={styles.spin} width={50} height={50} viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        <circle
          className={styles.circle}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
  );
}

export default Spinner;
